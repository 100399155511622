<template>
  <div class="block">
    <div class="center">
      <h5>Enter the host you want to connect to</h5>
      <div>
        Current Host: <b>{{ getCurrentEnvironment }}</b>
        Current Websocket: <b>{{ getCurrentWs }}</b>
      </div>

        <b-form-input
          id="input-formatter"
          v-model="host"
          placeholder="Enter your name"
          class="mt-3"
        ></b-form-input>

      <b-form-group
        description="The app will take the new host you will provide"
        class="mb-3 mt-4"
      >
        <b-form-input
          id="input-formatter"
          v-model="websocket"
          placeholder="Enter your name"
        ></b-form-input>
      </b-form-group>
      <b-button class="button mt-3" @click="changeEnvironment">Edit Host</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    host: "",
    websocket: "",
  }),
  methods: {
    changeEnvironment() {
      if (this.host.length > 0) {
        localStorage.setItem("host", this.host);
        localStorage.setItem("ws", this.websocket);
        localStorage.removeItem("user");
        location.reload(true);
      }
    }
  },
  computed: {
    getCurrentEnvironment() {
      return localStorage.getItem("host") == undefined
        ? localStorage.getItem("originalHost")
        : localStorage.getItem("host");
    },
    getCurrentWs() {
      return localStorage.getItem("ws") == undefined
        ? localStorage.getItem("originalWs")
        : localStorage.getItem("ws");
    }
  },
};
</script>

<style scoped>
.block {
  width: 100vw;
  height: 100vh;
}
.center {
  margin: auto;
  width: 50%;
  height: 40vh;
  transform: translate(0, 70%);
  border: 3px solid #29a1d3;
  padding: 10px;
  text-align: center;
}
.button {
  background-color: #29a1d3;
  transform: translate(0, 120%);
}
</style>
